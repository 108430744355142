/*!

=========================================================
* Helity Admin Console v.1
=========================================================

* Copyright 2020 Helity Srl (https://www.helity.it)
*
* Coded by F&F

=========================================================


*/

import React from "react";
import {
    Card,
    CardBody,
    Table,
    Row,
    Col,
    Button, Modal, ModalBody, ModalFooter,
    FormGroup,
    Form,
    Input
} from "reactstrap";

import { Redirect } from "react-router-dom";
import firebase from 'constants/firebase';
import { Editor } from '@tinymce/tinymce-react';
import DOMPurify from 'dompurify';

import _ from "underscore";
import InfiniteScroll from 'react-infinite-scroll-component';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

import moment from 'moment';
import 'moment/locale/it';

class ViewData extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModalDelete = this.toggleModalDelete.bind(this);
        this.toggleModalEmpty = this.toggleModalEmpty.bind(this);
        this.toggleModalEdit = this.toggleModalEdit.bind(this);
        this.toggleModalContent = this.toggleModalContent.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this);

        this.columns = JSON.parse(this.props.columns);

        this.state = {
            searchText:'',
            searchFun: _.debounce(()=>this.loadData(false), 350),
            data: [],
            deleteModal: null,
            editModal: null,
            emptyModal: null,
            name: null,
            offset: 0,
            num_rows: -1,
            order: 'id',
            sort: "DESC",
            required: [],
            redirect: null,
            checkEmpty: false,
            users: [],
            submitEdit: false,
            submitAdd: false,
            contentModal: null
        };
        
        this.i = 0;
        this.columns.forEach(value => {
            if(value["type"] === "select" && !Array.isArray(value["values"])){
                this.columns[this.i]["values_table"] = value["values"];
                this.columns[this.i]["values"] = [];
            }
            this.i++;
            this.state[value["name"]] = "";

            if(value['default']===null){
                this.state["new"+value["name"]] = "";
            } else {
                if(value["type"] === "date" && typeof(value["default"]) !== "undefined"){
                    this.state["new"+value["name"]] = moment().add(value["default"], 'days').format("lll");
                } else {
                    this.state["new"+value["name"]] = value['default'];
                }
            }

            if(value["required"]){
                this.state.required.push(value["name"]);
            }
        });

        if(typeof(this.props.history.location.props) !== "undefined" && typeof(this.props.history.location.props.pageName) !== "undefined"){
            this.pageName = this.props.history.location.props.pageName;
            for (const [key, value] of Object.entries(this.props.history.location.props.row)) {
                this.pageName = this.pageName.replace('$'+key+'$', value);
            }
        } else {
            this.pageName = this.props.pageName;
        }

        if(typeof(this.props.history.location.props) !== "undefined") this.createEnabled = this.props.history.location.props.createEnabled; else this.createEnabled = true;
    }

    componentDidMount() {
        this.loadData(false);

        let i = 0;
        this.columns.forEach(value => {
            if(typeof(value["values_table"]) !== "undefined"){
                if(value["values_table"] === "users"){ //TODO altre robe
                    value["values_i"] = i;
                    fetch("https://api.helity.site/view/user.php", {
                        method: 'POST',
                        headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                        },
                        body: "token="+this.props.token
                    }).then(response => response.json()).then(function (response) {
                        response["result"]["users"].forEach(function (user) {
                            this.state.users[user["uid"]] = user["displayName"];
                            this.columns[value["values_i"]]["values"].push({ user_id: user["uid"], display: user["displayName"] });
                        }.bind(this));
                        setTimeout(() => this.setState({}), 100);
                    }.bind(this));
                }
            }
            i++;
        });
    }

    loadData(newData = true){
        if(!newData){
            this.state.offset = 0;
        }
        let limit = 100;

        let endpoint, data_num, data;
        let where = {};
        if(typeof(this.props.history.location.props) !== "undefined"){
            if(this.props.history.location.props.type === "distinct"){
                this.columns.forEach((column)=>{
                    if(typeof(this.props.history.location.props.columns[column["name"]]) !== "undefined"){
                        where[column["name"]] = this.props.history.location.props.columns[column["name"]];
                        this.state['new'+column["name"]] = this.props.history.location.props.columns[column["name"]]
                        this.setState({})
                    }
                });    
            } else {
                where = this.props.history.location.props.columns;
            }
        }
        if(this.state.searchText !== ''){
            data_num = {
                "table": this.props.table,
                "search": this.state.searchText,
                "distinct": (this.props.distinct) ? this.columns.map((value) => value['name']) : null,
                "columns": this.columns.map((value) => value['name']),
                "where": where
            };
            data = {
                "table": this.props.table,
                "limit": limit,
                "offset": this.state.offset,
                "search": this.state.searchText,
                "order": this.state.order,
                "sort": this.state.sort,
                "distinct": (this.props.distinct) ? this.columns.map((value) => value['name']) : null,
                "columns": this.columns.map((value) => value['name']),
                "where": where
            };
            endpoint = "live_search.php";
        } else {
            data_num = {
                "table": this.props.table,
                "distinct": (this.props.distinct) ? this.columns.map((value) => value['name']) : null,
                "where": where
            };
            data = {
                "table": this.props.table,
                "limit": limit,
                "offset": this.state.offset,
                "order": this.state.order,
                "sort": this.state.sort,
                "distinct": (this.props.distinct) ? this.columns.map((value) => value['name']) : null,
                "where": where
            };
            endpoint = "data.php";
        }

        if(!newData){
            fetch("https://api.helity.site/view/rowsnumber.php", {
                method: 'POST',
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "token="+this.props.token+"&data="+JSON.stringify(data_num)+"&referer="+this.props.path
            }).then(response => response.json()).then(
                data => this.setState({num_rows: data["result"]})
            ).catch(e => {
                /*this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);*/
                this.props.history.push("/home");
            });
        }

        fetch("https://api.helity.site/view/"+endpoint, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "token="+this.props.token+"&data="+JSON.stringify(data)+"&referer="+this.props.path
        }).then(response => response.text()).then(data => {
                if(data === "permission_denied"){
                    this.props.displayAlert("Non hai il permesso di visualizzare questa risorsa.", "danger", "icon-simple-remove", 7);
                    this.props.history.push("/home");   
                } else {
                    let response = JSON.parse(data);
                    if(response){
                        this.setState({data: (!newData) ? response : this.state.data.concat(response), offset: this.state.offset+limit})    
                    } else {
                        this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
                        this.props.history.push("/home");            
                    }
                }
            }
        ).catch(e => {
            this.props.displayAlert("Errore di rete, riprovare.", "danger", "icon-simple-remove", 7);
            this.props.history.push("/home");
        });
    }

    toggleModalDelete(id) {
        this.setState({
            deleteModal: id
        });
    }

    toggleModalEmpty(id) {
        this.setState({
            emptyModal: id
        });
    }

    toggleModalContent(content){
        this.setState({
            contentModal: content
        });
    }

    toggleModalEdit(client) {
        if(client !== null){
            this.columns.forEach(value =>{
                this.state[value["name"]] = client[value["name"]];
            });
            this.setState({
                editModal: client
            });    
        } else {
            this.setState({
                editModal: client
            });    
        }
    }

    handleOnInputChange = (event) => {
        const query = event.target.value;
        this.setState({searchText:query});
        this.state.searchFun();
    };

    handleEditorChange = (content, editor) => {
        this.columns.forEach((value) => {
            if(editor.id === value["name"]){
                this.state[editor.id] = content;
            }
        });
    }

    handleChange(event) {
        this.columns.forEach((value) => {
            if(event.target.id === value["name"]){
                this.state[value["name"]] = event.target.value;
                this.setState({});
            }

            if(event.target.id === "new"+value["name"]){
                this.state["new"+value["name"]] = event.target.value;
                this.setState({});
            }
        });
    }

    render() {
        if(this.state.redirect !== null){
            return(
                <Redirect
                    to={this.state.redirect}
                    />
            );
        } else if(this.props.redirect !== null){
            return(
                <Redirect
                    to={this.props.redirect}
                    />
            );
        } else if(this.state.data !== null){
            return(
                <>
                <Modal isOpen={(this.state.deleteModal !== null)} toggle={() => {this.toggleModalDelete(null)}}>
                    {(this.state.deleteModal !== null) ?
                        <>
                        <div className="modal-header">
                        <h2 className="modal-title">
                            Elimina
                        </h2>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={() => {this.toggleModalDelete(null)}}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        </div>
                        <ModalBody>
                            <h3><b>Sei sicuro di voler eliminare questo elemento?</b></h3>
                            <p>L'azione è irreversibile.</p>
                            {
                                this.columns.map(value => {
                                    return(<tr><td><b>{value["displayName"]}: </b></td><td>{this.state.deleteModal[value["name"]]}</td></tr>
                                    )
                                })
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => {this.toggleModalDelete(null)}}>
                                Chiudi
                            </Button>
                            <Button color="primary" onClick={() => {
                                var msg = "Eliminazione effettuata!";
                                if(this.props.distinct){
                                    let cols = {};
                                    this.columns.forEach((value) => {
                                        cols[value["name"]] = this.state.deleteModal[value["name"]];
                                    });
                                    var data = {
                                        table: this.props.table,
                                        distinct: true,
                                        columns: cols
                                    };
                                } else {
                                    var data = {
                                        table: this.props.table,
                                        id: this.state.deleteModal["id"],
                                        distinct: false
                                    };
                                }
                                fetch("https://api.helity.site/delete/data.php", {
                                    method: 'POST',
                                    headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                                    },
                                    body: "token="+this.props.token+"&data="+JSON.stringify(data)+"&referer="+this.props.path
                                }).then((response) => response.text()).then(function(response){
                                    if(response === "ok"){
                                        this.props.displayAlert(msg, "success", "icon-check-2", 7);

                                        const newData = this.state.data;
                                        const index = newData.indexOf(this.state.deleteModal);
                                        if (index > -1) {
                                            newData.splice(index, 1);
                                        }

                                        this.setState({data: newData });
                                        this.toggleModalDelete(null);
                                    } else {
                                        this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
                                        this.toggleModalDelete(null);
                                    }
                                }.bind(this));
                            }}>
                                Conferma
                            </Button>
                        </ModalFooter>
                        </>
                    : null}
                </Modal>

                <Modal isOpen={(this.state.emptyModal !== null)} toggle={() => {this.toggleModalEmpty(null)}}>
                    {(this.state.emptyModal !== null) ?
                        <>
                        <div className="modal-header">
                        <h2 className="modal-title">
                            Elimina
                        </h2>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={() => {this.toggleModalEmpty(null)}}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        </div>
                        <ModalBody>
                            <h3><b>Sei sicuro di voler svuotare questo elemento ed eliminarlo?</b></h3>
                            <p>L'azione è irreversibile e comporterà la perdita di tutti i dati associati a questa riga.</p>
                            {
                                this.columns.map(value => {
                                    return(<tr><td><b>{value["displayName"]}: </b></td><td>{this.state.emptyModal[value["name"]]}</td></tr>
                                    )
                                })
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => {this.toggleModalEmpty(null)}}>
                                Chiudi
                            </Button>
                            <Button color="primary" onClick={() => {
                                var msg = "Eliminazione effettuata!";
                                if(this.props.distinct){
                                    let cols = {};
                                    this.columns.forEach((value) => {
                                        cols[value["name"]] = this.state.emptyModal[value["name"]];
                                    });
                                    var data = {
                                        table: this.props.table,
                                        distinct: true,
                                        columns: cols,
                                        empty: true
                                    };
                                } else {
                                    var data = {
                                        table: this.props.table,
                                        id: this.state.emptyModal["id"],
                                        distinct: false
                                    };
                                }
                                fetch("https://api.helity.site/delete/data.php", {
                                    method: 'POST',
                                    headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                                    },
                                    body: "token="+this.props.token+"&data="+JSON.stringify(data)+"&referer="+this.props.path
                                }).then((response) => response.text()).then(function(response){
                                    if(response === "ok"){
                                        this.props.displayAlert(msg, "success", "icon-check-2", 7);

                                        const newData = this.state.data;
                                        const index = newData.indexOf(this.state.emptyModal);
                                        if (index > -1) {
                                            newData.splice(index, 1);
                                        }

                                        this.setState({data: newData });
                                        this.toggleModalEmpty(null);
                                    } else {
                                        this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
                                        this.toggleModalEmpty(null);
                                    }
                                }.bind(this));
                            }}>
                                Conferma
                            </Button>
                        </ModalFooter>
                        </>
                    : null}
                </Modal>

                <Modal isOpen={(this.state.editModal !== null)} toggle={() => {this.toggleModalEdit(null)}}>
                    <div className="modal-header">
                        <h2 className="modal-title">
                            Modifica dati
                        </h2>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={() => {this.toggleModalEdit(null)}}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                    </div>
                    <Form onSubmit={(e) => {
                            e.preventDefault();
                            if(!this.state.submitEdit){
                                this.setState({ submitEdit: true });
                                if(this.state.editModal !== null){
                                    let dataOld = {};
                                    let dataReq = {};
                                    this.columns.forEach(value => {
                                        if(value["type"] === "date"){
                                            dataReq[value["name"]] = moment(this.state[value["name"]]).format("YYYY-MM-DD HH:mm:ss");
                                        } else {
                                            dataReq[value["name"]] = this.state[value["name"]];
                                        }
                                        if(this.props.distinct){
                                            dataOld[value['name']] = this.state.editModal[value['name']];
                                        }
                                    });
                                    var data = {
                                        distinct: this.props.distinct,
                                        table: this.props.table,
                                        id: this.state.editModal["id"],
                                        data: dataReq,
                                        old: dataOld,
                                    };
                                    fetch("https://api.helity.site/edit/data.php", {
                                        method: 'POST',
                                        headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                                        },
                                        body: "token="+this.props.token+"&data="+JSON.stringify(data)+"&referer="+this.props.path
                                    }).then((response) => response.text()).then(function (response){
                                        this.setState({ submitEdit: false });
                                        if(response === "ok"){
                                            const newData = this.state.data.map(function (p){
                                                if(p === this.state.editModal){
                                                    let r = { ...p};
                                                    this.columns.forEach(value => {
                                                        r[value["name"]] = this.state[value["name"]];
                                                    });
                                                    return r;
                                                } else return p;
                                                }.bind(this)
                                            );
                                            this.setState({data: newData });
                                            this.toggleModalEdit(null);
                                            this.props.displayAlert("Modifica riuscita!", "success", "icon-check-2", 7);
                                        } else {
                                            this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
                                            this.toggleModalEdit(null);
                                        }
                                    }.bind(this));
                                }
                            }
                        }}>
                        <ModalBody>
                            {
                                this.columns.map((value) => {
                                    switch(value["type"]){
                                        case 'select':
                                            return (<>
                                                <FormGroup>
                                                    <label htmlFor={value["name"]}>
                                                        {value["displayName"]}
                                                    </label>
                                                    <Input
                                                            placeholder={value["displayName"]}
                                                            id={value["name"]}
                                                            type="select"
                                                            onChange={this.handleChange}
                                                            value={this.state[value["name"]]}
                                                            disabled={(value["disabled"] === true) ? true : false}
                                                        >
                                                            {(value["default"] === "currentUser")?
                                                            <option value={firebase.auth().currentUser["uid"]} selected >{firebase.auth().currentUser["displayName"]}</option>
                                                            :<option value={""} selected disabled>{value["displayName"]}</option>}
                                                            {
                                                                value["values"].map((value) => {
                                                                    if(typeof(value) === "object"){
                                                                        return <option value={value["user_id"]}>{value["display"]}</option>;
                                                                    } else {
                                                                        return <option value={value}>{value}</option>;
                                                                    }
                                                                })
                                                            }
                                                        </Input>
                                                </FormGroup>
                                            </>);
                                        case 'date':
                                            return (<>
                                                <FormGroup>
                                                    <label htmlFor={value["name"]}>
                                                        {value["displayName"]}
                                                    </label>
                                                    <Datetime
                                                        value={moment(this.state[value["name"]]).format("lll")}
                                                        onChange={date => {
                                                            this.state[value["name"]] = date;
                                                            this.setState({});
                                                        }}
                                                        dateFormat={"DD MMM YYYY"}
                                                        locale={"it"}
                                                        disabled={(value["disabled"] === true) ? true : false}
                                                        id={value["name"]}
                                                    />
                                                </FormGroup>
                                            </>);
                                        case 'tinymce':
                                            return (<>
                                                <FormGroup>
                                                    <label htmlFor={value["name"]}>
                                                        {value["displayName"]}
                                                    </label>
                                                    <Editor
                                                        apiKey='62zfkcqvhaijlv3a52utr4lat8zwh9kiogoz8p7push3s5pw'
                                                        initialValue={this.state[value["name"]]}
                                                        init={{
                                                        height: 500,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        toolbar:
                                                            'undo redo | formatselect | bold italic backcolor | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | help'
                                                        }}
                                                        onEditorChange={this.handleEditorChange}
                                                        id={value["name"]}
                                                    />
                                                </FormGroup>
                                            </>);
                                        default:
                                            return (<>
                                                <FormGroup>
                                                    <label htmlFor={value["name"]}>
                                                        {value["displayName"]}
                                                    </label>
                                                    <Input placeholder={value["displayName"]} id={value["name"]} type={value["type"]} onChange={this.handleChange} value={this.state[value["name"]]} disabled={(value["disabled"] === true) ? true : false}/>
                                                </FormGroup>
                                            </>);
                                    }
                                })
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => {this.toggleModalEdit(null)}}>
                                Chiudi
                            </Button>
                            <Button color="primary" type="submit" disabled={this.state.submitEdit}>
                                Conferma
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>

                <Modal isOpen={(this.state.contentModal !== null)} toggle={() => {this.toggleModalContent(null)}}>
                    {(this.state.contentModal !== null) ?
                        <>
                        <div className="modal-header">
                        <h2 className="modal-title">
                            {this.state.contentModal.name}
                        </h2>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={() => {this.toggleModalContent(null)}}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        </div>
                        <ModalBody>
                            <div style={{backgroundColor: 'rgba(142, 142, 142, 0.19)'}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.contentModal.content)}}></div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => {this.toggleModalContent(null)}}>
                                Chiudi
                            </Button>
                        </ModalFooter>
                        </>
                    : null}
                </Modal>

                <div className="content">
                    <Row>
                        <Col md="12">
                        <Card>
                            <CardBody>
                                <Form onSubmit={(e)=>{
                                    e.preventDefault();
                                    if(!this.state.submitAdd){
                                        this.setState({ submitAdd: true });
                                        let toggle = true;
                                        this.state.required.forEach((value) => {
                                            if(this.state["new"+value] === "") toggle = false;
                                        });
                                        if(toggle){
                                            let data;
                                            if(typeof(this.props.history.location.props) !== "undefined"){
                                                data = this.props.history.location.props.columns;
                                            } else {
                                                data = {};
                                            }
                                            this.columns.forEach(value => {
                                                if(value["type"] === "date"){
                                                    data[value["name"]] = moment(this.state["new"+value["name"]], 'lll').format("YYYY-MM-DD HH:mm:ss");
                                                } else {
                                                    data[value["name"]] = this.state["new"+value["name"]];
                                                }
                                            });
                                            
    
                                            fetch("https://api.helity.site/in/data.php", {
                                                method: 'POST',
                                                headers: {
                                                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                                                },
                                                body: "token="+this.props.token+"&table="+this.props.table+"&data="+JSON.stringify(data)+"&referer="+this.props.path
                                            }).then((response) => response.json()).then((response)=>{
                                                this.setState({ submitAdd: false });
                                                this.state.data.unshift(response);
                                                this.columns.forEach(value => {
                                                    if(typeof(value["default"]) === "undefined"){
                                                        this.state["new"+value["name"]] = "";
                                                    } else {
                                                        if(value["type"] === "date" && typeof(value["default"]) !== "undefined"){
                                                            this.state["new"+value["name"]] = moment().add(value["default"], 'days').format("lll");
                                                        } else {
                                                            this.state["new"+value["name"]] = value['default'];
                                                        }
                                                    }
                                                });
                                                this.setState({ data: this.state.data });
                                                if(response["log"] === false){
                                                    this.props.displayAlert("Inserimento effettuato ma con un errore. Contattare l'assistenza!", "warning", "icon-check-2", 7);
                                                } else {
                                                    this.props.displayAlert("Inserimento effettuato.", "success", "icon-check-2", 7);
                                                }
                                            }).catch((e) => {
                                                this.setState({ submitAdd: false });
                                                this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
                                            });
                                            this.toggleModalEdit(null);
                                        } else {
                                            this.setState({ submitAdd: false });
                                            this.props.displayAlert("Completa tutti i campi!", "danger", "icon-simple-remove", 7);
                                        }
                                    }
                                }}>
                                    <div className="d-flex bd-highlight">
                                        <div className="flex-grow-1"><h3>{this.pageName}</h3></div>
                                        <div className="flex-grow-1"></div>

                                        <div className="flex-grow-1">
                                            <Input
                                                id="inlineFormInputGroup"
                                                placeholder="Cerca"
                                                type="text"
                                                onChange={this.handleOnInputChange}
                                                onKeyPress={e => {
                                                    if(e.which === 13){
                                                        e.preventDefault();
                                                        let el = document.querySelector( ':focus' );
                                                        if(el) el.blur();
                                                    }
                                                }}
                                                />
                                        </div>
                                    </div>
                                    <InfiniteScroll
                                        dataLength={this.state.data.length}
                                        next={this.loadData}
                                        hasMore={(this.state.num_rows === -1 || this.state.num_rows > this.state.data.length)}
                                        loader={<h4>Caricamento...</h4>}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                            <b>Non ci sono ulteriori elementi.</b>
                                            </p>
                                        }
                                        >
                                    <div className="table-responsive table-min-height">
                                    <Table>
                                        <thead>
                                        {(this.props.auths.includes("create_"+this.props.path) && this.createEnabled)
                                                ? 
                                                    (<tr>
                                                        {
                                                            this.columns.map((value) => {
                                                                if(!value["hidden"]){
                                                                        switch(value["type"]){
                                                                            case 'select':
                                                                                return (
                                                                                    <td>
                                                                                        <FormGroup>
                                                                                            <Input
                                                                                                id={"new"+value["name"]}
                                                                                                type="select"
                                                                                                onChange={this.handleChange}
                                                                                                value={this.state["new"+value["name"]]}
                                                                                                disabled={(value["disabled"] === true) ? true : false}
                                                                                            >
                                                                                                {(value["default"] === "currentUser")?
                                                                                                    <option value={firebase.auth().currentUser["uid"]} selected>{firebase.auth().currentUser["displayName"]}</option>
                                                                                                    :<option value={""} selected disabled>{value["displayName"]+((value['required'])?' (*)':'')}</option>}
                                                                                                {
                                                                                                    value["values"].map((value) => {
                                                                                                        if(typeof(value) === "object"){
                                                                                                            return <option value={value["user_id"]}>{value["display"]}</option>;
                                                                                                        } else {
                                                                                                            return <option value={value}>{value}</option>;
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            </Input>
                                                                                        </FormGroup>
                                                                                    </td>);        
                                                                            case 'date':
                                                                                return (
                                                                                 <td>
                                                                                    <FormGroup>
                                                                                        <Datetime
                                                                                            value={this.state["new"+value["name"]]}
                                                                                            onChange={date => {
                                                                                                this.state["new"+value["name"]] = date;
                                                                                                this.setState({});
                                                                                            }}
                                                                                            dateFormat={"DD MMM YYYY"}
                                                                                            locale={"it"}
                                                                                            disabled={(value["disabled"] === true) ? true : false}
                                                                                            placeholder={((value['required'])?' (*)':'')}
                                                                                        />
                                                                                    </FormGroup>
                                                                                    </td>);
                                                                            default:
                                                                                return (
                                                                                    <td>
                                                                                        <FormGroup>
                                                                                            <Input
                                                                                                placeholder={value["displayName"]+((value['required'])?' (*)':'')}
                                                                                                id={"new"+value["name"]}
                                                                                                type="text" 
                                                                                                onChange={this.handleChange}
                                                                                                value={this.state["new"+value["name"]]}
                                                                                                disabled={(value["disabled"] === true) ? true : false}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </td>);
                                                                        }
                                                                    
                                                                
                                                                }
                                                            })
                                                        }
                                                        <td className="text-right">
                                                        <Button title="Aggiungi" className="btn-icon" size="sm" type="submit" disabled={this.state.submitAdd}>
                                                            <i className="tim-icons icon-simple-add"></i>
                                                        </Button>{` `}
                                                        </td>
                                                    </tr>) : null }
                                            <tr>
                                                {
                                                    this.columns.map((value) => {
                                                        if(!value["hidden"])
                                                            return <th>{value["displayName"]} {(value["name"] === this.state.order) ?
                                                            <a href='' onClick={function (e) {
                                                                e.preventDefault();
                                                                this.state.order = value["name"];
                                                                this.state.sort = (this.state.sort === "ASC") ? "DESC" : "ASC";
                                                                this.loadData(false);
                                                            }.bind(this)}><i className={(this.state.sort === "ASC") ?  "tim-icons icon-minimal-down" : "tim-icons icon-minimal-up"}></i></a>
                                                            :
                                                            <a href='' onClick={function (e) {
                                                                e.preventDefault();
                                                                this.state.order = value["name"];
                                                                this.state.sort = "ASC";
                                                                this.loadData(false);
                                                            }.bind(this)}><i className="tim-icons icon-minimal-right"></i></a>
                                                            }</th>;
                                                    })
                                                }
                                                <th className="text-right">Azioni</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data.map(function (row) {
                                                if(this.state.checkEmpty === false && this.props.distinct){
                                                    let checkEmpty = true;
                                                    this.columns.forEach((value) => {
                                                        if(value !== "" || value !== null) checkEmpty = false;
                                                    });
                                                    this.state.checkEmpty = checkEmpty;
                                                }
                                                return (<tr>
                                                    {
                                                        this.columns.map((value) => {
                                                            if(!value["hidden"]){
                                                                if(value["type"] === "date"){
                                                                    return <td>{moment(row[value["name"]], "YYYY-MM-DD HH:mm:ss").format('lll')}</td>;
                                                                } else if(value["type"] === "tinymce") {
                                                                    return <td>{(row[value["name"]] === "" || row[value["name"]] === null) ? <small><i>{"Senza "+value["displayName"].toLowerCase()}</i></small> : <u><i><a href='#' onClick={() => this.toggleModalContent({ name: value["displayName"], content: row[value["name"]] })} >{"Visualizza "+value["displayName"].toLowerCase()}</a></i></u>  }</td>;
                                                                } else if(value["type"] === "confirm"){
                                                                    return <td>
                                                                        <Button 
                                                                            title="Incarico"
                                                                            className={"btn-link btn-icon " + ((row[value["name"]]) ? "btn-success" : "btn-danger")} size="sm" onClick={() => {
                                                                                var dataReq = {};
                                                                                this.columns.forEach(v => {
                                                                                    dataReq[v["name"]] = row[v["name"]];
                                                                                });
                                                                                dataReq[value["name"]] = (dataReq[value["name"]] === 1) ? 0 : 1;

                                                                                var data = {
                                                                                    distinct: this.props.distinct,
                                                                                    table: this.props.table,
                                                                                    id: row["id"],
                                                                                    data: dataReq,
                                                                                    old: row,
                                                                                };
                                                                                fetch("https://api.helity.site/edit/data.php", {
                                                                                    method: 'POST',
                                                                                    headers: {
                                                                                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                                                                                    },
                                                                                    body: "token="+this.props.token+"&data="+JSON.stringify(data)+"&referer="+this.props.path
                                                                                }).then((response) => response.text()).then(function (response){
                                                                                    const newData = this.state.data.map(function (p){
                                                                                        if(p === row){
                                                                                            return dataReq;
                                                                                        } else return p;
                                                                                        }.bind(this)
                                                                                    );
                                                                                    this.setState({data: newData });
                                                                                }.bind(this));
                                                                            }}
                                                                            >
                                                                            <i className={"tim-icons " + ((row[value["name"]]) ? "icon-check-2" : "icon-tap-02")}></i>
                                                                        </Button>
                                                                    </td>;
                                                                } else {
                                                                    return <td>{(row[value["name"]] === "" || row[value["name"]] === null) ? <small><i>{"Senza "+value["displayName"].toLowerCase()}</i></small> : (value["name"] === "user_id") ? (row[value["name"]] === firebase.auth().currentUser["uid"]) ? firebase.auth().currentUser["displayName"] : this.state.users[row[value["name"]]] : row[value["name"]] }</td>;
                                                                }
                                                            }
                                                        })
                                                    }
                                                    <td className="text-right">
                                                        
                                                        {
                                                            (this.props.links !== null) ? JSON.parse(this.props.links).map((element) => {
                                                                return(<>
                                                                    <Button
                                                                            title={element["buttonTitle"]}
                                                                            className="btn-icon"
                                                                            size="sm"
                                                                            disabled={(row[element['is']] === 0 || row[element['is']] === null)}
                                                                            onClick={() => {
                                                                            if(element["type"] === "distinct"){
                                                                                let cols = {};
                                                                                this.columns.forEach((value) => {
                                                                                    cols[value["name"]] = row[value["name"]];
                                                                                });
                                                                                this.setState({
                                                                                    redirect: {
                                                                                        pathname: "/"+element["path"],
                                                                                        props: {
                                                                                            columns: cols,
                                                                                            row: row,
                                                                                            createEnabled: true,
                                                                                            type: element["type"]
                                                                                        }
                                                                                    }
                                                                                });
                                                                            } else if(element["type"] === "column"){
                                                                                let cols = {};
                                                                                cols[element["where"]] = row[element["is"]];
                                                                                this.setState({
                                                                                    redirect: {
                                                                                        pathname: "/"+element["path"],
                                                                                        props: {
                                                                                            columns: cols,
                                                                                            row: row,
                                                                                            pageName: element["pageName"],
                                                                                            createEnabled: (element["where"] !== "id"),
                                                                                            type: element["type"]
                                                                                        }
                                                                                    }
                                                                                });
                                                                            }
                                                                        }}>
                                                                            <i className={"tim-icons icon-"+element["icon"]}></i>
                                                                    </Button>{` `}</>
                                                                );
                                                            }):null
                                                        }
                                                        <Button 
                                                        title="Modifica"                                                        
                                                        className="btn-icon" size="sm" onClick={() => { //Edit button
                                                            this.toggleModalEdit(row);
                                                        }} disabled={!this.props.auths.includes("edit_"+this.props.path)}>
                                                            <i className="tim-icons icon-pencil"></i>
                                                        </Button>{` `}
                                                        <Button 
                                                        title="Elimina"
                                                        className="btn-icon" size="sm" onClick={() => { //Delete button
                                                            this.toggleModalDelete(row);
                                                        }}
                                                        disabled={!this.props.auths.includes("delete_"+this.props.path)}
                                                        >
                                                            <i className="tim-icons icon-trash-simple"></i>
                                                        </Button>{` `}
                                                        <Button 
                                                        title="Svuota"
                                                        className="btn-icon btn-danger" size="sm" onClick={() => { //empty button
                                                            this.toggleModalEmpty(row);
                                                        }}
                                                        disabled={!this.props.auths.includes("create_auths")}
                                                        >
                                                            <i className="tim-icons icon-simple-remove"></i>
                                                        </Button>
                                                        
                                                    </td>
                                                </tr>);
                                            }.bind(this))}
                                        </tbody>
                                    </Table></div>
                                    </InfiniteScroll>
                                </Form>
                                </CardBody>
                        </Card>
                    </Col>
                    </Row>
                </div>
                </>
            );
        } else {
            return (<>
                <div className="content">
                    <p>Caricamento dati...</p>
                </div>
            </>);
        }
    }
}

export default ViewData;