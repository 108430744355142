import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDH8KpZ7fxCI_TosgPDcrfDG0gT6ssqYh0",
    authDomain: "gestionale-helity.firebaseapp.com",
    databaseURL: "https://gestionale-helity.firebaseio.com",
    projectId: "gestionale-helity",
    storageBucket: "gestionale-helity.appspot.com",
    messagingSenderId: "337051945691",
    appId: "1:337051945691:web:ef5cfc847da8a9eaaedafe",
    measurementId: "G-RXV8KQY278"
};
firebase.initializeApp(firebaseConfig);

export default firebase;