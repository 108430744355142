import React from 'react';
import firebase from 'constants/firebase';
import NotificationAlert from "react-notification-alert";

import ImgLogo from 'assets/img/logo_big.png';

import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input
} from "reactstrap";

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', password: '', buttonDisabled: false };

        this.handleChange = this.handleChange.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    handleChange(event) {
        if(event.target.id === "email"){
            this.setState({ email: event.target.value });
        }
        if(event.target.id === "password"){
            this.setState({ password: event.target.value });
        }
    }

    handleCloseDialog() {
        this.setState({ open: false });
    }

    handleLogin(e) {
        e.preventDefault();
        this.setState({ buttonDisabled: true });
        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((user) => {
            this.props.history.push('/home');
        }).catch((e) => {
            this.setState({ buttonDisabled: false });
            var options = {
                place: "bc",
                message: (
                    <div>
                    {e.message}
                    </div>
                ),
                type: "danger",
                icon: "tim-icons icon-alert-circle-exc",
                autoDismiss: 7
            };
            this.refs.notificationAlert.notificationAlert(options);

            const data = {
                "email": this.state.email,
                "error": e.message
            };
            fetch("https://api.helity.site/in/log.php", {
                method: 'POST',
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "data="+JSON.stringify(data)
            });
        });
    }

    render() {
        return (
            <>
            <div className="react-notification-alert-container">
                <NotificationAlert ref="notificationAlert" />
            </div>
            <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <br />
                <div className="content" style={{textAlign: "center"}}>
                <img src={ImgLogo} width="120px" />
                <h1 className="title">Login</h1>
                    <Card style={{ width: '25rem' }}>
                        <CardBody>
                            <Form onSubmit={this.handleLogin}>
                                <FormGroup>
                                    <label htmlFor="email">
                                        Email
                                    </label>
                                    <Input placeholder="email@helity.it" id="email" type="email" onChange={this.handleChange} value={this.state.email}/>
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="password">
                                        Password
                                    </label>
                                    <Input placeholder="********" id="password" type="password" onChange={this.handleChange} value={this.state.password}/>
                                </FormGroup>
                                <Button
                                    className="btn-fill" 
                                    color="blue" 
                                    style={{width: "100%"}}
                                    type="submit"
                                    disabled={this.state.buttonDisabled}>
                                    Accedi
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </div>
          </>
        );
    }
}

export default SignIn;