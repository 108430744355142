import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";

import { Button, Modal, ModalBody, ModalFooter, UncontrolledTooltip} from "reactstrap";
import DOMPurify from 'dompurify';

import AdminNavbar from "components/AdminNavbar.js";
import Footer from "components/Footer.js";
import Sidebar from "components/Sidebar.js";
import ViewData from "views/ViewData.js";
import Homepage from "views/Homepage.js";
import Utenti from "views/Utenti.js";
import NotificationAlert from 'react-notification-alert';

import Logo from "assets/img/torri_logo.png";
import moment from 'moment';
import 'moment/locale/it';
import globals from '../constants/globals.js';

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "blue",
      sidebarOpened:
        document.documentElement.className.indexOf("nav-open") !== -1,
      routes: [],
      routesComponent: [],
      contentModal: null,
      redirect: null
    };
    this.toggleModalContent = this.toggleModalContent.bind(this);
    this.displayAlert = this.displayAlert.bind(this);
  }

  toggleModalContent(content){
    this.setState({
        contentModal: content
    });
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }

    fetch("https://api.helity.site/view/homepage.php", {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "token="+this.props.token+"&referer=notes"
        }).then(response => response.text()).then(data => {
                if(data === "permission_denied"){
                    this.props.displayAlert("Non hai il permesso di visualizzare questa risorsa.", "danger", "icon-simple-remove", 7);
                } else {
                    let response = JSON.parse(data);
                    if(response){
                        globals.notes = [];
                        response["notes"].forEach(note => {
                            if(moment(note["expiry"], "YYYY-MM-DD HH:mm:ss").isSame(moment().clone().startOf('day'), 'd')){
                                globals.notes.push(note);
                            }
                        });
                    } else {
                        this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
                    }
                }
            }
        ).catch(e => {
            this.props.displayAlert("Errore di rete, riprovare.", "danger", "icon-simple-remove", 7);
        });

    let data = {
      "table": "pages"
    };
    fetch("https://api.helity.site/view/data.php", {
        method: 'POST',
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: "token="+this.props.token+"&data="+JSON.stringify(data)
    }).then(response => response.json()).then(data => {
      let routesComponents = data.map((prop, key) => {
        return (
          <Route
            path={"/"+prop["path"]}
            key={key}
            render={() =>
              <ViewData
                auths={this.props.auths}
                history={this.props.history}
                displayAlert={this.displayAlert}
                token={this.props.token}
                pageName={prop["pageName"]}
                columns={prop["columnsData"]}
                table={prop["tableName"]}
                distinct={prop["distinctBool"]}
                path={prop["path"]}
                disabled={prop['disabled']}
                links={prop['links']}
                redirect={this.state.redirect}
              />
            }
          />
        );
      });

      data.unshift({
        path: "users",
        pageName: "Utenti",
        icon: "single-02",
        component: Utenti,
        layout: "/",
        auth: "view_users"
      });
      routesComponents.unshift(<Route
        path={"/users"}
        key={"users"}
        render={() =>
          <Utenti
            auths={this.props.auths}
            history={this.props.history}
            displayAlert={this.displayAlert}
            token={this.props.token}
            path={"users"}
            redirect={this.state.redirect}
          />
        }
      />);

      data.unshift({
        path: "home",
        pageName: "Homepage",
        icon: "chart-pie-36",
        component: Homepage,
        layout: "/",
        auth: null
      });
      routesComponents.unshift(<Route
        path={"/home"}
        key={"home"}
        render={() =>
          <Homepage
            auths={this.props.auths}
            history={this.props.history}
            displayAlert={this.displayAlert}
            token={this.props.token}
            path={"home"}
            toggleModalContent={this.toggleModalContent}
            redirect={this.state.redirect}
          />
        }
      />);

      
      this.setState({ routes: data, routesComponents: routesComponents });
    }).catch(e => {
        console.log(e);
    });
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };
  
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };

  displayAlert(msg, type, icon, autoDismiss){
    var options = {
        place: "bc",
        message: (
            <div>
            {msg}
            </div>
        ),
        type: type,
        icon: "tim-icons "+icon,
        autoDismiss: autoDismiss
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  render() {
    return (
      <>
        <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={this.state.routes}
            bgColor={this.state.backgroundColor}
            logo={{
              outterLink: "https://gestionale.helity.it/",
              text: "Gestionale Helity",
              imgSrc: Logo
            }}
            toggleSidebar={this.toggleSidebar}
            auths={this.props.auths}
          />
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <AdminNavbar
              {...this.props}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={this.state.sidebarOpened}
              toggleModalContent={this.toggleModalContent}
            />
            <Modal isOpen={(this.state.contentModal !== null)} toggle={() => {this.toggleModalContent(null)}}>
                    {(this.state.contentModal !== null) ?
                        <>
                        <div className="modal-header">
                        <h2 className="modal-title">
                            {this.state.contentModal.name}
                        </h2>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={() => {this.toggleModalContent(null)}}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        </div>
                        <ModalBody>
                            <div style={{backgroundColor: 'rgba(142, 142, 142, 0.19)'}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.contentModal.content)}}></div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => {
                              let cols = {};
                              cols["id"] = this.state.contentModal["id"];
                              this.setState({
                                  redirect: {
                                      pathname: "/notes",
                                      props: {
                                          columns: cols,
                                          row: {},
                                          pageName: "Nota",
                                          createEnabled: false
                                      }
                                  }
                              });
                              this.toggleModalContent(null);
                              setTimeout(() => this.setState({
                                redirect: null
                              }), 100);
                            }}>
                                Modifica
                            </Button>

                            <Button color="secondary" onClick={() => {this.toggleModalContent(null)}}>
                                Chiudi
                            </Button>
                        </ModalFooter>
                        </>
                    : null}
                </Modal>
            <Switch>
              {this.state.routesComponents}
            </Switch>
            <Footer fluid />
          </div>
        </div>
      </>
    );
  }
}

export default Admin;
