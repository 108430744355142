/*!

=========================================================
* Helity Admin Console v.1
=========================================================

* Copyright 2020 Helity Srl (https://www.helity.it)
*
* Coded by F&F

=========================================================


*/

import React from "react";
import {
    Card,
    CardBody,
    Table,
    Row,
    Col,
    Button, Modal, ModalBody, ModalFooter,
    FormGroup,
    Form,
    Input
} from "reactstrap";

import firebase from 'constants/firebase';
import {get_password_word} from 'constants/utils';

class Utenti extends React.Component {
    constructor(props) {
        super(props);
        this.state = { searchText:'', data: null, deleteModal: null, editModal: null, manageModal: null, name: null, email: null, newname: "", newemail: "", authsUser: null };

        this.toggleModalDelete = this.toggleModalDelete.bind(this);
        this.toggleModalEdit = this.toggleModalEdit.bind(this);
        this.toggleModalManage = this.toggleModalManage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this);

        /*this.permissions = [
            {
                "name": "Utenti",
                "table": "users"
            },
            {
                "name": "Censimento",
                "table": "censimento"
            },
            {
                "name": "Autorizzazioni",
                "table": "auths"
            },
            {
                "name": "Note",
                "table": "notes"
            },
        ];*/
    }

    componentDidMount() {
        this.loadData();
    }

    loadData(){
        fetch("https://api.helity.site/view/user.php", {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "token="+this.props.token
        }).then(response => response.json()).then(
            data => this.setState({data: data})
        ).catch(e => {
            this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
            this.props.history.push("/home");
        });
    }

    toggleModalDelete(uid) {
        this.setState({
            deleteModal: uid
        });
    }

    toggleModalManage(uid) {
        if(uid !== null){
            this.setState({
                manageModal: uid
            });    
            this.loadAuths(uid["uid"]);
        } else {
            this.setState({
                manageModal: null,
                authsUser: null
            });    
        }
    }

    toggleModalEdit(user) {
        if(user !== null){
            this.setState({
                editModal: user,
                name: user["displayName"],
                email: user["email"]
            });    
        } else {
            this.setState({
                editModal: user
            });    
        }
    }
    

    toggleAuth(table, auth, uid, active){
        var endpoint;
        const data = {
            "user_id": uid,
            "action1": auth,
            "action2": table,
        };
        if(active) endpoint = 'delete'; else endpoint = 'in';
        fetch("https://api.helity.site/"+endpoint+"/auth.php", {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "token="+this.props.token+"&data="+JSON.stringify(data)+"&referer=auths"
        }).then((response) => response.text()).then((response)=>{
            if(response === "ok"){
                this.loadAuths(uid);
            } else {
                this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
            }
        });
    }

    loadAuths(uid){
        const data = {
            "table": "auths",
            "column": "user_id",
            "user_id": uid
        };
        fetch("https://api.helity.site/view/data.php", {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "token="+this.props.token+"&data="+JSON.stringify(data)+"&referer="+this.props.path
        }).then((response) => response.json()).then((response)=>{
            if(response){
                var auths = {};
                response.forEach((value)=>{
                    for (const [key, value1] of Object.entries(value)) {
                        if(key !== "id" && key !== "pages" && key !== "user_id" && value1 !== null) { //TODO
                            auths[key] = value1.split(",");
                        } else if(value1 === null){
                            auths[key] = [];
                        }
                    }
                });
                this.setState({authsUser: auths});
            } else {
                this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
            }
        });
        /*const data = {
            "table": "auths",
            "column": "user_id",
            "user_id": uid
        };
        fetch("https://api.helity.site/view/data.php", {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "token="+this.props.token+"&data="+JSON.stringify(data)
        }).then((response) => response.json()).then((response)=>{
            if(response){
                var auths = [];
                response.forEach((value)=>{
                    auths.push(value["do"]);
                });
                this.setState({authsUser: auths});
            } else {
                this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
            }
        });*/
    }

    handleOnInputChange = (event) => {
        const query = event.target.value;
        this.setState({searchText:query});
    };

    handleChange(event) {
        if(event.target.id === "name"){
            this.setState({ name: event.target.value });
        }
        if(event.target.id === "email"){
            this.setState({ email: event.target.value });
        }
        if(event.target.id === "newname"){
            this.setState({ newname: event.target.value });
        }
        if(event.target.id === "newemail"){
            this.setState({ newemail: event.target.value });
        }
    }

    render() {
        if(this.state.data != null){
            return(
                <>
                <Modal isOpen={(this.state.deleteModal !== null)} toggle={() => {this.toggleModalDelete(null)}}>
                    {(this.state.deleteModal !== null) ?
                        <>
                        <div className="modal-header">
                        <h2 className="modal-title">
                            Sei sicuro?
                        </h2>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={() => {this.toggleModalDelete(null)}}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        </div>
                        <ModalBody>
                            <p>Sei sicuro di voler {(this.state.deleteModal["disabled"]) ? "attivare" : "disattivare"} {this.state.deleteModal["email"]}?</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => {this.toggleModalDelete(null)}}>
                                Chiudi
                            </Button>
                            <Button color="primary" onClick={() => {
                                var msg = "L'utente è stato "+ ((this.state.deleteModal["disabled"]) ? "attivato" : "disattivato") +"!";
                                this.toggleModalDelete(null);
                                var data = {
                                    user_id: this.state.deleteModal["uid"],
                                    disabled: !this.state.deleteModal["disabled"],
                                };
                                fetch("https://api.helity.site/delete/user.php", {
                                    method: 'POST',
                                    headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                                    },
                                    body: "token="+this.props.token+"&data="+JSON.stringify(data)
                                }).then((response) => response.text()).then((response)=>{
                                    if(response === "ok"){
                                        this.props.displayAlert(msg, "success", "icon-check-2", 7);
                                    } else {
                                        this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
                                    }
                                    this.loadData();
                                });
                            }}>
                                Si
                            </Button>
                        </ModalFooter>
                        </>
                    : null}
                </Modal>

                <Modal isOpen={(this.state.editModal !== null)} toggle={() => {this.toggleModalEdit(null)}}>
                    <div className="modal-header">
                        <h2 className="modal-title">
                            Modifica utente
                        </h2>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={() => {this.toggleModalEdit(null)}}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                    </div>
                    <Form onSubmit={(e) => {
                            e.preventDefault();
                            if(this.state.editModal !== null){
                                var data = {
                                    user_id: this.state.editModal["uid"],
                                    name: this.state.name,
                                    email: this.state.email
                                };
                                fetch("https://api.helity.site/edit/user.php", {
                                    method: 'POST',
                                    headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                                    },
                                    body: "token="+this.props.token+"&data="+JSON.stringify(data)
                                }).then((response) => response.text()).then((response)=>{
                                    if(response === "ok"){
                                        this.props.displayAlert("L'utente è stato modificato!", "success", "icon-check-2", 7);
                                    } else {
                                        this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
                                    }
                                    this.loadData();
                                });
                                this.toggleModalEdit(null);
                            }
                        }}>
                        <ModalBody>
                            <FormGroup>
                                <label htmlFor="name">
                                    Nome
                                </label>
                                <Input placeholder="Mario Rossi" id="name" type="text" onChange={this.handleChange} value={this.state.name}/>
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="password">
                                    Email
                                </label>
                                <Input placeholder="email@helity.it" id="email" type="email" onChange={this.handleChange} value={this.state.email}/>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => {this.toggleModalEdit(null)}}>
                                Chiudi
                            </Button>
                            <Button color="primary" type="submit">
                                Si
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>

                <Modal isOpen={(this.state.manageModal !== null)} toggle={() => {this.toggleModalManage(null)}}>
                    <div className="modal-header">
                    <h2 className="modal-title">
                        Gestione utente
                    </h2>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={() => {this.toggleModalManage(null)}}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    </div>
                    <ModalBody>
                    
                        <p>Utente selezionato: {(this.state.manageModal !== null) ? this.state.manageModal["email"] : "" }</p>
                        
                        {(this.state.authsUser) ? 
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Crea</th>
                                    <th>Visualizza</th>
                                    <th>Modifica</th>
                                    <th>Elimina</th>
                                </tr>
                            </thead>
                            
                            <tbody style={{textAlign: "center"}}>
                            {/*this.permissions.map((value) => {
                                if(this.state.manageModal !== null){
                                    return (
                                        <>
                                            <tr>
                                                <th>{value["name"]}</th>
                                                {["create", "view", "edit", "delete"].map((valueauth) => {
                                                    if(value["table"] !== "auths" || (value["table"] === "auths" && valueauth === "create")) return <td><Input type="checkbox" disabled={this.state.manageModal["disabled"]} className="authCheckbox" checked={this.state.authsUser.includes(valueauth+"_"+value["table"])} onChange={() => this.toggleAuth(value["table"], valueauth, this.state.manageModal["uid"], this.state.authsUser.includes(valueauth+"_"+value["table"]))} /></td>;
                                                    else return null;
                                                })}
                                            </tr>
                                        </>
                                    );
                                }
                            })*/
                            Object.keys(this.state.authsUser).map(function(key, index) {
                                if(this.state.manageModal !== null){
                                    return (
                                        <>
                                            <tr>
                                                <th>{key}</th>
                                                {["create", "view", "edit", "delete"].map((valueauth) => {
                                                    if(key !== "auths" && key !== "logs")
                                                        return <td>
                                                            <Input type="checkbox"
                                                                disabled={this.state.manageModal["disabled"]}
                                                                className="authCheckbox"
                                                                checked={this.state.authsUser[key].includes(valueauth)}
                                                                onChange={() => this.toggleAuth(key, valueauth, this.state.manageModal["uid"], this.state.authsUser[key].includes(valueauth))}
                                                                />
                                                        </td>;
                                                    else return null;
                                                })}
                                            </tr>
                                        </>
                                    );
                                }
                            }.bind(this))
                            }
                            </tbody>
                        </table>: null}

                        <Button color="primary" onClick={() => {
                            this.toggleModalManage(null);
                            var data = {
                                email: this.state.manageModal["email"],
                                requestType: "PASSWORD_RESET"
                            };
                            fetch("https://api.helity.site/edit/user_newpassword.php", {
                                method: 'POST',
                                headers: {
                                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                                },
                                body: "token="+this.props.token+"&data="+JSON.stringify(data)
                            }).then((response) => response.text()).then((response)=>{
                                if(response === "ok"){
                                    this.props.displayAlert("È stata inviata una email per il ripristino della password.", "success", "icon-check-2", 7);
                                } else {
                                    this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
                                }
                                this.loadData();
                            });
                        }}>
                            Invia nuova password
                        </Button>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => {this.toggleModalManage(null)}}>
                            Chiudi
                        </Button>
                    </ModalFooter>
                </Modal>

                <div className="content">
                    <Row>
                        <Col md="12">
                        <Card>
                            <CardBody>
                                <Form onSubmit={(e)=>{
                                    e.preventDefault();
                                    if(this.state.newemail !== "" && this.state.newname !== ""){
                                        var password = get_password_word(7);
                                        var data = {
                                            email: this.state.newemail,
                                            password: password,
                                            displayName: this.state.newname,
                                            returnSecureToken: true
                                        };
                                        fetch("https://api.helity.site/in/user.php", {
                                            method: 'POST',
                                            headers: {
                                            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                                            },
                                            body: "token="+this.props.token+"&data="+JSON.stringify(data)
                                        }).then((response) => response.text()).then((response)=>{
                                            if(response === "ok"){
                                                this.props.displayAlert("L'utente è stato creato! La sua password è: " + password, "success", "icon-check-2", 7); //TODO display name
                                            } else {
                                                this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
                                            }
                                            this.loadData();
                                        });
                                        this.toggleModalEdit(null);
                                    } else {
                                        this.props.displayAlert("Completa tutti i campi!", "danger", "icon-simple-remove", 7);
                                    }
                                }}>
                                    <div class="d-flex bd-highlight">
                                        <div class="flex-grow-1"><h3>Utenti</h3></div>
                                        <div class="flex-grow-1"></div>

                                        <div class="flex-grow-1">
                                            <Input id="inlineFormInputGroup" placeholder="Cerca" type="text"  onChange={this.handleOnInputChange} />
                                        </div>
                                    </div>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Stato</th>
                                                <th>Nome</th>
                                                <th>Email</th>
                                                <th className="text-right">Azioni</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data["result"]["users"].map(function (row) {
                                                if((row['displayName'].toLowerCase().indexOf(this.state.searchText.toLowerCase())!==-1)||(row['email'].toLowerCase().indexOf(this.state.searchText.toLowerCase())!==-1))
                                                {
                                                return (<tr>
                                                    <td>{(row["disabled"]) ?
                                                        <Button className="btn-icon" size="sm" onClick={() => { //Delete button
                                                            this.toggleModalDelete(row);
                                                        }}
                                                        disabled=
                                                        {!this.props.auths.includes("delete_users")}
                                                        >
                                                            <i className="tim-icons icon-simple-remove user-status"></i>
                                                        </Button>
                                                        :
                                                        <Button className="btn-icon" size="sm" onClick={() => { //Delete button
                                                            this.toggleModalDelete(row);
                                                            }} disabled=
                                                            {!this.props.auths.includes("delete_users")}>
                                                            <i className="tim-icons icon-check-2 user-status"></i>
                                                        </Button>
                                                    }</td>
                                                    <td>{row["displayName"]}</td>
                                                    <td>{row["email"]}</td>
                                                    <td className="text-right">
                                                        <Button className="btn-icon" size="sm" onClick={() => { //Edit button
                                                            this.toggleModalEdit(row);
                                                        }} disabled={!this.props.auths.includes("edit_users")}>
                                                            <i className="tim-icons icon-pencil"></i>
                                                        </Button>{` `}
                                                        <Button className="btn-icon" size="sm" onClick={() => {
                                                            this.toggleModalManage(row);
                                                        }} disabled={!this.props.auths.includes("create_auths")}>
                                                            <i className="tim-icons icon-settings-gear-63"></i>
                                                        </Button>{` `}
                                                    </td>
                                                </tr>);
                                                }
                                            }.bind(this))}
                                            
                                            {(this.props.auths.includes("create_users"))
                                                ? <tr>
                                                    <td>
                                                        
                                                    </td>
                                                    <td>
                                                        <FormGroup>
                                                            <Input placeholder="Nome" id="newname" type="text" onChange={this.handleChange} value={this.state.newname}/>
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <FormGroup>
                                                            <Input placeholder="Email" id="newemail" type="text" onChange={this.handleChange} value={this.state.newemail}/>
                                                        </FormGroup>
                                                    </td>
                                                    <td className="text-right">
                                                        <Button className="btn-icon" size="sm" type="submit">
                                                            <i className="tim-icons icon-simple-add"></i>
                                                        </Button>{` `}
                                                    </td>
                                                </tr>
                                                : null}
                                        </tbody>
                                    </Table>
                                </Form>
                                </CardBody>
                        </Card>
                    </Col>
                    </Row>
                </div>
                </>
            );
        } else {
            return (<>
                <div className="content">
                    <p>Caricamento dati...</p>
                </div>
            </>);
        }
    }
}

export default Utenti;