/*!

=========================================================
* Helity Admin Console v.1
=========================================================

* Copyright 2020 Helity Srl (https://www.helity.it)
*
* Coded by F&F

=========================================================


*/

import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
  Modal, ModalBody, ModalFooter,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { Redirect } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/it';
import globals from '../constants/globals.js';

class Homepage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            redirect: null,
        };
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount(){
        this.loadData();
    }

    loadData(){
        fetch("https://api.helity.site/view/homepage.php", {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "token="+this.props.token+"&referer=notes"
        }).then(response => response.text()).then(data => {
                if(data === "permission_denied"){
                    this.props.displayAlert("Non hai il permesso di visualizzare questa risorsa.", "danger", "icon-simple-remove", 7);
                } else {
                    let response = JSON.parse(data);
                    if(response){
                        this.setState({data: response });
                    } else {
                        this.props.displayAlert("Errore sconosciuto.", "danger", "icon-simple-remove", 7);
                    }
                }
            }
        ).catch(e => {
            this.props.displayAlert("Errore di rete, riprovare.", "danger", "icon-simple-remove", 7);
        });
    }

    render() {
        if(this.state.redirect !== null){
            return(
                <Redirect
                    to={this.state.redirect}
                    />
            );
        }
        if(this.props.redirect !== null){
            return(
                <Redirect
                    to={this.props.redirect}
                    />
            );
        }
        return (
            <>
            <div className="content">
                <Row>
                <Col lg="6" md="12">
                    <Card className="card-tasks">
                    <CardHeader>
                        <h6 className="title d-inline">In scadenza ({(this.state.data !== null && typeof(this.state.data.notes) !== "undefined") ? this.state.data.notes.length : "0"})</h6>
                        <p className="d-inline"> nei prossimi 7 giorni</p>
                        {
                            /*
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    caret
                                    className="btn-icon"
                                    color="link"
                                    data-toggle="dropdown"
                                    type="button"
                                >
                                    <i className="tim-icons icon-settings-gear-63" />
                                </DropdownToggle>
                                <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                                    <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                    >
                                    Action
                                    </DropdownItem>
                                    <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                    >
                                    Another action
                                    </DropdownItem>
                                    <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                    >
                                    Something else
                                    </DropdownItem>
                                </DropdownMenu>
                                </UncontrolledDropdown>
                            */
                        }
                    </CardHeader>
                    <CardBody>
                        <div className="table-full-width table-responsive">
                        <Table>
                            <tbody>
                                {
                                    (this.state.data !== null && typeof(this.state.data.notes) !== "undefined") ? this.state.data.notes.map((value) => {
                                        return <tr>
                                            <td>
                                            <FormGroup check>
                                                <Label check>
                                                <Input disabled defaultValue="" type="checkbox" />
                                                <span className="form-check-sign">
                                                    <span className="check" />
                                                </span>
                                                </Label>
                                            </FormGroup>
                                            </td>
                                            <td>
                                            <p className="title">{value["title"]}</p>
                                            {
                                                (typeof(value["censimento"]) !== "undefined") ? 
                                                <p className="text-muted">
                                                    {value["censimento"]["pre"]+" "+value["censimento"]["name"]+" "+value["censimento"]["street_number"]+" "+value["censimento"]["ZIP"]+" "+value["censimento"]["firstname"]+" "+value["censimento"]["lastname"]}</p> 
                                                    : null
                                            }<p align='right'>{moment(value["expiry"]).format("lll")}
                                            </p>
                                            </td>
                                            <td className="td-actions text-right">
                                            <Button
                                                title="Visualizza"
                                                color="link"
                                                id="tooltip636901683_v"
                                                title=""
                                                type="button"
                                                onClick={() => this.props.toggleModalContent({ name: value["title"], content: value["dati"], id: value["id"] })}
                                            >
                                                <i className="tim-icons icon-notes" />
                                            </Button>
                                            <Button
                                                title="Modifica"
                                                color="link"
                                                id="tooltip636901683"
                                                title=""
                                                type="button"
                                                onClick={() => {
                                                    let cols = {};
                                                    cols["id"] = value["id"];
                                                    this.setState({
                                                        redirect: {
                                                            pathname: "/notes",
                                                            props: {
                                                                columns: cols,
                                                                row: {},
                                                                pageName: "Nota",
                                                                createEnabled: false
                                                            }
                                                        }
                                                    });
                                                }}
                                            >
                                                <i className="tim-icons icon-pencil" />
                                            </Button>
                                            {/*<UncontrolledTooltip
                                                delay={0}
                                                target="tooltip636901683"
                                                placement="right"
                                            >
                                                Modifica
                                            </UncontrolledTooltip>
                                            <UncontrolledTooltip
                                                delay={0}
                                                target="tooltip636901683_v"
                                                placement="right"
                                            >
                                                Visualizza
                                            </UncontrolledTooltip>*/}
                                            </td>
                                        </tr>;
                                    }) : null
                                }
                            </tbody>
                        </Table>
                        </div>
                    </CardBody>
                    </Card>
                </Col>
                </Row>
            </div>
            </>
        );        
    }
}

export default Homepage;