import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import "firebase/auth";
import firebase from "constants/firebase";

import AdminLayout from 'layouts/Admin.js';
import Login from 'layouts/Login.js';

import "assets/scss/black-dashboard-react.scss";
import "assets/css/nucleo-icons.css";
import "assets/css/main.css";

const hist = createBrowserHistory();

class MainRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { state: "loading", auths: [], token: "" };

    firebase.auth().onAuthStateChanged(function(user) {
      if(user !== null){
        this.setState({ state: "logged", token: user["ya"] });

        setInterval(()=>{
          firebase.auth().signOut();
        }, 45 * 60 * 1000);

        const data = {
          "table": "auths",
          "column": "user_id",
          "user_id": user["uid"]
        };
        fetch("https://api.helity.site/view/data.php", {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "token="+user["ya"]+"&data="+JSON.stringify(data)
        }).then(response => response.json()).then(function (response){
          let auths = [];
          for (const [key, value1] of Object.entries(response[0])) {
            if(key !== "id" && key !== "user_id") {
                if(response[0][key] !== null) response[0][key].split(",").forEach(element => {
                  auths.push(element+"_"+key);
                });
            }
          }
          
          this.setState({ auths: auths });
        }.bind(this));
      } else {
        this.setState({ state: "login" });
      }
    }.bind(this));

    this.tokenReload = this.tokenReload.bind(this);
  }

  tokenReload(){
    firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
      this.setState({ token: idToken });
    }.bind(this));
  }

  render(){
    switch(this.state.state){
      case 'loading':
        return <></>;
      case 'login':
        return(
          <>
          <Route
              path="/login"
              component={Login}
            />
            <Redirect from="*" to="/login"/>
          </>
        );
      case 'logged':
        return <Route path="/" render={props => <AdminLayout {...props}  auths={this.state.auths} token={this.state.token} />} />;
      case 'not_authorized':
        return <>
          <h1>Non hai il permesso di accedere a questa pagina.</h1>
        </>;
    };
  }
}

ReactDOM.render(<Router history={hist}><Switch><MainRouter /></Switch></Router>,
  document.getElementById('root')
);

serviceWorker.unregister();